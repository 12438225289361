import React, { FunctionComponent } from 'react'
import Box from '@material-ui/core/Box'
import GitHubIcon from '@material-ui/icons/GitHub'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import { makeStyles, createStyles } from '@material-ui/core/styles'

import ButtonNew from './ButtonNew'
import ButtonOpen from './ButtonOpen'
import ButtonSave from './ButtonSave'
import ButtonExport from './ButtonExport'
import Padding from '../LeftBar/modules/PackConfig/Padding'

const useStyles = makeStyles(({ zIndex, spacing }) =>
  createStyles({
    root: {
      position: 'relative',
      zIndex: zIndex.appBar,
    },
    appName: {
      fontSize: '1.25rem',
      fontWeight: 'bolder',
      marginRight: spacing(4),
    },
    appSpan: {
      fontSize: '1.25rem',
      color: '#4caf50'
    },
    appNameSup: {
      fontWeight: 'lighter',
      fontSize: '0.5em',
      marginLeft: '0.5rem',
    },
    appAHover: {
      color: '#ffeb3b',
      textDecoration: 'none',
      padding:'10px'
    },
    appA: {
      color: '#ffff',
      textDecoration: 'none',
      padding:'10px'
    }
    ,
    btn: {
      textTransform: 'none',
    },
  }),
)

const TitleBar: FunctionComponent<unknown> = () => {
  const classes = useStyles()

  return (
    <Box
      className={classes.root}
      bgcolor='background.titleBar'
      paddingX={4}
      display='flex'
      alignItems='center'
    >
      <Typography variant='h1' className={classes.appName}>
        <span className={classes.appSpan}>Ant</span>
        Mod BMF
        <sup className={classes.appNameSup}>EN</sup>
      </Typography>
      <Box flex='auto' paddingX={4}>
        <ButtonNew className={classes.btn} />
        <ButtonOpen className={classes.btn} />
        <ButtonSave className={classes.btn} />
        <ButtonExport className={classes.btn} />
      </Box>
      <a className={classes.appAHover} href='https://bmf.antmod.tw'>English</a>
      <a className={classes.appA} href='https://bmfcn.antmod.tw'>中文</a>     
      {/* <IconButton
        size='small'
        component='a'
        href='https://bmfcn.antmod.tw'
        title='中文'
      >
        切换中文语系
      </IconButton> */}
    </Box>
  )
}
export default TitleBar
